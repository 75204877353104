/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-12 18:34:19
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-13 22:27:58
 */
import React, { useEffect } from 'react'
import { Menu, Button, message, Dropdown } from 'antd'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { showAccountModal } from '../user/userSlice'
import FolderModal from '../folders/FolderModal'
import ProjectModal from '../projects/ProjectModal'
import MoveModal from '../folders/MoveModal'
import { FolderList } from '../folders/Folders'
import { getFolders, showFolderModal, setActivedFolder } from '../folders/folderSlice'
import { showProjectModal } from '../projects/projectSlice'

import './Sidebar.css'
import { FileOutlined, FolderOutlined, PlusOutlined } from '@ant-design/icons';

export const Sidebar = () => {

    const useQuery = new URLSearchParams(useLocation().search)
    const dispatch = useDispatch()
    //const isFiled = useSelector(state => state.projects.filters.isFiled)
    ///const isFiled = 1
    //const keyword = useQuery.get('keyword')
    const userType = useSelector(state => state.config.hasTeammatePW ? state.user.userType : 2)

    const activedFolder = useSelector(state => state.folder.activedFolder)
    //const activedFolder = useSelector(state => state.folder.activedFolder)

    const params = useParams()

    useEffect(() => {
        dispatch(getFolders({ fid: 0 }))
        if (params.folder) {
            if (params.folder !== 'all' && params.folder !== 'working') {
                dispatch(getFolders({ path: params.folder, type: 'actived' }))
            }
        }
    }, [])

    useEffect(() => {
        if (params.folder === 'all') {
            dispatch(setActivedFolder({ title: '全部', fid: -1, key: -1, path: 'all' }))
        } else if (params.folder === 'working') {
            dispatch(setActivedFolder({ title: '正在进行', fid: -2, key: -2, path: 'working' }))
        }
    }, [params])


    const createProject = () => {
        if (userType >= 2) {
            dispatch(showProjectModal({ visible: true }))
        } else {
            dispatch(showAccountModal(true))
        }
    }

    const createFolder = () => {
        if (userType >= 2) {
            dispatch(showFolderModal({ visible: true }))
        } else {
            dispatch(showAccountModal(true))
        }
    }

    const menu = (
        <Menu
            selectedKeys={[]}
            onClick={(item) => {
                if (item.key === '1') createProject()
                if (item.key === '2') createFolder()
            }
            }
        >
            <Menu.Item key="1"  ><FileOutlined /> 新建项目</Menu.Item>
            <Menu.Item key="2" ><FolderOutlined /> 新建文件夹</Menu.Item>
        </Menu>
    )

    return (
        <>
            {/* <Menu className="sider_menu" selectedKeys={[selectedKey]} onClick={({ item, key, keyPath }) => onClick(key, keyword)} >
                <Menu.Item key="ALL" ><Icon type="desktop" /><span>全部项目</span></Menu.Item>
                <Menu.Item key="WORKING" ><Icon type="play-circle-o" /><span>正在进行</span></Menu.Item>
            </Menu> */}
            <div className="sider-btn" >
                <Dropdown overlay={menu} arrow >
                    <Button type="primary" icon={<PlusOutlined />} >
                        新建
                    </Button>
                </Dropdown>
            </div>
            <ProjectModal />
            <FolderModal />
            <MoveModal />
            <FolderList folder={activedFolder} />
            {/* <Button icon={<PlusOutlined />} type="dashed"  className="folder-btn" >新建文件夹</Button> */}
            {/* <div className="sider_btn" ><Button type="primary" onClick={() => createProject(userType)} >新建</Button></div>
            <Menu className="sider_menu" selectedKeys={[selectedKey]} onClick={({ item, key, keyPath }) => onClick(key, keyword)} >
                <Menu.Item key="ALL" ><Icon type="desktop" /><span>全部项目</span></Menu.Item>
                <Menu.Item key="WORKING" ><Icon type="play-circle-o" /><span>正在进行</span></Menu.Item>
                <Menu.Item key="FILED"  ><Icon type="clock-circle-o" /><span>神已归档</span></Menu.Item>
            </Menu> */}
        </>
    )
}
