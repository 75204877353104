/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-10 19:15:51
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-08 22:07:58
 */
// import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//   },
// });

import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import counterReducer from '../features/counter/counterSlice'
import configReducer from '../features/navbar/configSlice'
import projectReducer from '../features/projects/projectSlice'
import folderReducer from '../features/folders/folderSlice'
import userSlice from '../features/user/userSlice'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from "redux-thunk"

export const history = createBrowserHistory()

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  counter: counterReducer,
  config: configReducer,
  project: projectReducer,
  folder: folderReducer,
  user: userSlice
})

export const store = function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState, compose(applyMiddleware(routerMiddleware(history), thunk)),
  )
  return store
}
