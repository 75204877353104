/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-18 13:10:11
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-19 13:17:00
 */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Form, Modal, Input, Popconfirm, Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { updateConifg, showSettingModal, verify, generateUsertoken } from './configSlice'
//import { Modal, Form, Input, Tooltip, Icon, Button, Popconfirm } from 'antd'
//import './Modal.css'
const FormItem = Form.Item

const { TextArea } = Input


const SettingModal = () => {

    const config = useSelector(state => state.config)
    let loading = config.updatingConfig

    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { setFieldsValue, resetFields, isFieldTouched } = form

    useEffect(() => {
        setFieldsValue(config)
    }, [config])


    const handleCancel = () => {
        dispatch(showSettingModal(false))
    }

    const handleSubmit = async () => {
        try {
            const data = await form.validateFields()
            if (config.hasAdminAuth) {
                if (data.ipWhitelist === config.ipWhitelist && data.visitorPassword === config.visitorPassword && data.teammatePassword === config.teammatePassword) {
                    handleCancel()
                } else {
                    dispatch(updateConifg(data))
                }
            } else {
                dispatch(verify(data.adPassword))
            }

        } catch (errorInfo) {
            console.error('Failed:', errorInfo);
        }
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    }

    return (
        <Modal
            forceRender
            visible={config.showSettingModal}
            title={"服务器设置"}
            wrapClassName="vertical-center-modal"
            onCancel={() => {
                handleCancel()
                resetFields()
            }}
            afterClose={() => {
                handleCancel()
                resetFields()
            }}
            width={640}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    {loading ? '提交中' : '提交'}
                </Button>
            ]}
        >
            <Form className="projectForm" form={form}  >
                {config.hasAdminAuth ?
                    <>
                        <FormItem
                            label="访客密码"
                            name="visitorPassword"
                            {...formItemLayout}
                        >
                            <Input placeholder="设置后，访问团队主页时需先输入密码" />
                        </FormItem>
                        <FormItem
                            label="成员密码"
                            name="teammatePassword"
                            {...formItemLayout}
                        >
                            <Input placeholder="设置后，对项目新建/修改/删除时需先输入密码" />
                        </FormItem>
                        <FormItem
                            label="白名单IP"
                            name="ipWhitelist"
                            {...formItemLayout}
                        >
                            <TextArea rows={4} placeholder="请输入可访问的 IP 地址，1 行 1 个，* 可表示 ip 末段为任意值" />
                        </FormItem>
                        <FormItem
                            label={
                                <span>
                                    Chrome 扩展密码
                                    <Popconfirm title="重置后所有已授权的用户需重新登陆" onConfirm={() => { dispatch(generateUsertoken()) }} okText="好的" cancelText="取消">
                                    <ReloadOutlined />
                                    </Popconfirm>
                                </span>}
                            name="chromeSecretKey"
                            {...formItemLayout}
                        >
                            <TextArea disabled={true} rows={4} />
                        </FormItem>
                        <FormItem
                            label="配置检测" 
                            {...formItemLayout}
                        >
                            <Button onClick={()=>window.open('/api/config/check')}>开始检测</Button>
                        </FormItem>
                    </>
                    :
                    <FormItem
                        label="管理密码"
                        name="adPassword"
                        {...formItemLayout}
                    >
                        <Input placeholder="请输入管理密码，才能继续设置" />

                    </FormItem>
                }
            </Form>
        </Modal>
    )
}

export default SettingModal



