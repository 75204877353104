/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-24 20:54:50
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-11 00:02:04
 */
import React, { useState, useEffect } from 'react'
import { Form, Modal, Button, message } from 'antd'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router'
//import { showAccountModal } from '../navbar/configSlice'
import { moveProjects } from '../projects/projectSlice'
import { showMoveModal, updateFolder } from './folderSlice'
import { FolderList } from './Folders'
import './Folder.css'

const MoveModal = () => {

    //const folder = useSelector(state => state.folder.selectedFolder)
    const loading = useSelector(state => state.folder.moving)
    const visible = useSelector(state => state.folder.showMoveModal)
    const ids = useSelector(state => state.folder.movedIds)
    //const type = useSelector(state => state.folder.moveType)
    const selectedFolder = useSelector(state => state.folder.selectedFolder)
    const activedFolder = useSelector(state => state.folder.activedFolder)

    const dispatch = useDispatch()
    // const [form] = Form.useForm()
    // const { setFieldsValue, validateFields, resetFields } = form

    const handleCancel = () => {
        dispatch(showMoveModal({ visible: false }))
    }

    const handleSubmit = async () => {

        try {
            if (selectedFolder.fid === undefined) {
                message.warn('请选择目标文件夹')
            } else {
                //if (type === 'project') {
                    dispatch(moveProjects({
                        fid: selectedFolder.fid,
                        activedFid: activedFolder.fid,
                        pids: ids
                    }))
                // } else {
                //     dispatch(updateFolder({
                //         fid: ids[0],
                //         parent: selectedFolder.fid,
                //     }))
                // }
            }
        } catch (errorInfo) {
            console.error('Failed:', errorInfo);
        }
    }


    return (
        <Modal
            forceRender
            visible={visible}
            title={'移动到 ' + (selectedFolder.name || '')}
            wrapClassName="vertical-center-modal"
            onCancel={handleCancel}
            afterClose={handleCancel}
            width={480}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    {loading ? '提交中' : '提交'}
                </Button>
            ]}
        >
            <div className='target-folder'>
                <FolderList isNav={false} folder={selectedFolder} />
            </div>
        </Modal>
    )

}

export default MoveModal