/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-13 22:10:32
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-17 16:49:59
 */
import React, { useState, useEffect, useRef } from 'react'
import { Input } from 'antd'
import { SearchOutlined, EnterOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router'



export const Search = () => {

    const useQuery = new URLSearchParams(useLocation().search)
    const dispatch = useDispatch()
    const defaultKeyword = useQuery.get('keyword')
    const [onFocus, setOnFocus] = useState(false)
    const [keyword, setKeyword] = useState(defaultKeyword)

    const searchRef = useRef(null)

    useEffect(() => {
        if (onFocus) {
            searchRef.current.focus()
        }
    }, [onFocus])

    const handleSearch = () => {
        let newUrl = keyword ? './?keyword=' + keyword : './'
        dispatch(push(newUrl))
    }

    const emitEmpty = () => {
        setOnFocus(true)
        setKeyword('')
        dispatch(push('./'))
    }

    const onChangeKeyword = (e) => {
        setKeyword(e.target.value)
    }

    const onblur = (e) => {
        setOnFocus(false)
        if (e.target.value === '' && keyword) {
            dispatch(push('./'))
        }
    }

    let suffix = null
    if (!onFocus && keyword) suffix = <CloseCircleOutlined onMouseDown={emitEmpty} />
    if (onFocus && keyword) suffix = <EnterOutlined onMouseDown={handleSearch} />
    return (
        <Input
            placeholder="搜索原型..."
            className="searchInput"
            size="large"
            prefix={<SearchOutlined />}
            suffix={suffix}
            onPressEnter={handleSearch}
            onChange={onChangeKeyword}
            onFocus={() => setOnFocus(true)}
            onBlur={onblur}
            defaultValue={keyword}
            value={keyword}
            ref={searchRef}
        />
    )


}


// class Search extends React.Component {

//     constructor(props) {
//         super(props)

//         this.state = {
//             onFocus: false,
//             keyword: this.props.keyword
//         }

//         this.handleSearch = () => {
//             let newUrl = this.state.keyword ? './all/?keyword=' + this.state.keyword : './'
//             this.props.dispatch(push(newUrl))
//         }

//         this.emitEmpty = () => {
//             this.searchInput.focus()
//             this.setState(Object.assign({}, this.state, { keyword: '', onFocus: true }))
//         }

//         this.onChangeKeyword = (e) => {
//             this.setState(Object.assign({}, this.state, { keyword: e.target.value }))
//         }

//         this.onblur = (e) => {
//             this.setState(Object.assign({}, this.state, { onFocus: false }))
//             if (e.target.value === '' && this.props.keyword) {
//                 this.props.dispatch(push('./'))
//             }
//         }
//     }


//     render() {
//         const { onFocus, keyword } = this.state
//         let suffix = null
//         if (!onFocus && keyword) suffix = <Icon type="close-circle" onMouseDown={this.emitEmpty} />
//         if (onFocus && keyword) suffix = <Icon type="enter" onMouseDown={this.handleSearch} />
//         return (
//             <Input
//                 placeholder="搜索原型..."
//                 className="searchInput"
//                 size="large"
//                 prefix={<Icon type="search" />}
//                 suffix={suffix}
//                 onPressEnter={this.handleSearch}
//                 onChange={this.onChangeKeyword}
//                 onFocus={() => { this.setState(Object.assign({}, this.state, { onFocus: true })) }}
//                 onBlur={this.onblur}
//                 defaultValue={this.props.keyword}
//                 value={keyword}
//                 ref={node => this.searchInput = node}
//             />
//         )
//     }

// }

// const mapStateToProps = (state, ownProps) => ({
//     keyword: new URLSearchParams(state.routing.location.search).get('keyword')
// })

// export default connect(mapStateToProps)(Search)