/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-24 20:54:50
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-13 22:08:43
 */
import React, { useState, useEffect, useRef } from 'react'
import { Menu, Tree, Dropdown, Modal } from 'antd'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router'
import { showAccountModal } from '../user/userSlice'
import { getFolders, showFolderModal, deleteFolder, updateFolder, showMoveModal, setSelectedFolder, setActivedFolder, moveFolder } from '../folders/folderSlice'
import { FolderOutlined, DesktopOutlined, RocketOutlined, MoreOutlined, EditOutlined, FolderAddOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import './Folder.css'
import { sort } from 'semver';
const { confirm } = Modal
const TreeNode = Tree.TreeNode

Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

export const FolderList = ({ isNav, folder }) => {

    isNav = isNav === undefined ? true : isNav

    const dispatch = useDispatch()
    const userType = useSelector(state => state.config.hasTeammatePW ? state.user.userType : 2)
    const folders = useSelector(state => state.folder.list)
    //const loading = useSelector(state => state.folder.loading)
    const [expandedKeys, setExpandedKeys] = useState([])
    const [treeData, setTreeData] = useState([])
    const [selectedKey, setSelectedKey] = useState(-2)

    const login = () => {
        dispatch(showAccountModal(true))
    }

    useEffect(() => {

        if (folder.path === 'all') {
            setSelectedKey(-1)
        } else if (folder.path === 'working') {
            setSelectedKey(-2)
        } else {
            setSelectedKey(folder.fid)
        }

        if (folder) {
            let newKeys = expandedKeys.concat()

            if (folder.children && folder.children.length > 0 && folder.fid) {
                if (newKeys.indexOf(folder.fid) === -1) {
                    newKeys.push(folder.fid)
                } else {
                    newKeys.remove(folder.fid)
                }
                setExpandedKeys(newKeys)
            }
            if (folder.parent) {
                if (newKeys.indexOf(folder.parent) === -1) {
                    newKeys.push(folder.parent)
                    setExpandedKeys(newKeys)
                }
            }
        }

    }, [folder])


    useEffect(() => {
        let data = renderTreeData(folders)
        let defaultTree = isNav ? [
            { title: '正在进行', fid: -2, key: -2, children: [], path: 'working', iisLeaf: true, icon: <RocketOutlined /> },
            { title: '全部项目', fid: -1, key: -1, children: [], path: 'all', isLeaf: true, icon: <DesktopOutlined /> },
        ] : []
        setTreeData(defaultTree.concat(data))

    }, [folders])


    const renderTreeData = (folders) => {

        let treeData = []

        folders.map((item) => {

            let isLeaf = item.isLeaf || true
            let icon = <FolderOutlined />

            if (item.children && item.children.length > 0) {
                isLeaf = false
                icon = null
            }

            if (folder.path === item.path) {
                setSelectedKey(item.fid)
            }

            let data = {
                title: <>   {isNav ?
                    <Dropdown overlay={menu(item)} trigger={["contextMenu"]}>
                        <span className={item.parent ? 'leaf-folder-name' : 'folder-name'}>{item.name}</span>
                    </Dropdown>
                    : <span className={item.parent ? 'leaf-folder-name' : 'folder-name'}>{item.name}</span>}
                </>,
                fid: item.fid,
                key: item.fid,
                name: item.name,
                path: item.path,
                parent: item.parent,
                weight: item.weight,
                isLeaf,
                icon,
                selectable: true,
                allowDrop: item.parent === 0
            }

            if (item.children && item.children.length > 0) {
                data.children = renderTreeData(item.children)
            }

            treeData.push(data)

        })

        return treeData
    }


    const menu = (folder) => (
        <Menu
            selectedKeys={[]}
            onClick={({ key }) => {

                if (userType < 2) {
                    return login()
                }

                switch (key) {
                    case '0':
                        dispatch(showFolderModal({ visible: true, parent: folder.fid }))
                        break

                    case '1':
                        dispatch(showFolderModal({ visible: true, folder }))
                        break
                    case '2':
                        //dispatch(showMoveModal({ visible: true, ids: [folder.fid], type: 'folder' }))
                        break
                    case '3':
                        confirm({
                            title: '是否要删除文件夹 ' + folder.name + ' ?',
                            content: '删除后不可恢复',
                            onOk: () => {
                                dispatch(deleteFolder(folder.fid))
                            }
                        })
                        break

                    default:

                }

            }
            }
        >
            {folder.parent === 0 ?
                <Menu.Item key="0"  ><PlusOutlined /> 新建子文件夹</Menu.Item>
                :
                null
            }
            <Menu.Item key="1"  ><EditOutlined /> 编辑</Menu.Item>
            {/* <Menu.Item key="2" ><DeleteOutlined /> 移动</Menu.Item> */}
            <Menu.Item key="3" ><DeleteOutlined /> 删除</Menu.Item>
        </Menu>
    )


    const onExpand = (keys, props) => {
        // let newKeys = expandedKeys.concat()
        // if (props.expanded) {
        //     newKeys.push(props.node.key)
        // } else {
        //     newKeys.remove(props.node.key)
        // }
        // setExpandedKeys(newKeys)
    }


    const onSelect = (selectedKeys, e) => {

        const node = e.node
        //setSelectedKey(node.key)

        if (isNav) {
            dispatch(setActivedFolder(node))
            dispatch(push('/' + node.path))
        } else {
            dispatch(setSelectedFolder(node))
        }

        if (!node.children && !node.parent) {
            dispatch(getFolders({ fid: node.key, type: isNav ? 'actived' : 'selected' }))
        }

    }

    const onDrop = (e) => {

        let weight = 0 //权重
        let nextWeight = 0 //后一个元素的权重
        let parent = e.dropToGap ? e.node.parent : e.node.fid//目标父节点
        let parentPos = 0 //目标父节点位置
        let newPos = e.dropPosition //插入位置

        if (e.dropToGap) {
            let list = treeData

            //let skip = 2

            if (e.node.parent !== 0) {
                parentPos = e.node.pos.split('-')[1]
                //parent = list[parentPos].fid
                list = list[parentPos].children || []
            } else {
                newPos -= 2
            }

            if (list.length > e.dropPosition) {
                nextWeight = list[e.dropPosition]['weight']
            }
            weight = parseInt((e.node.weight + nextWeight) / 2)



        } else {
            parent = e.node.fid
            newPos = 0
        }

        let sort = e.node.weight - nextWeight < 10 ? 1 : 0

        //let newPos = e.dropToGap ? e.dropPosition  : 0

        dispatch(moveFolder({
            fid: e.dragNode.fid,
            originFolder: e.dragNode,
            //originParent: e.dragNode.parent,
            //originParentPos,
            newPos,
            parent,
            parentPos,
            weight,
            sort
        }))

        //let weight = parseInt((e.node.weight + folders[e.dropPosition - 2]['weight']) / 2)
        //console.log(e)

    }

    return (
        <Tree.DirectoryTree
            //loadData={onLoadData}
            draggable={isNav ? node => node.key > 0 : false}
            switcherIcon={<></>}
            className="folders-tree"
            showIcon={true}
            expandedKeys={expandedKeys}
            //defaultSelectedKeys={[defaultSelectedKey]}
            selectedKeys={[selectedKey]}
            onExpand={onExpand}
            onSelect={onSelect}
            onDrop={onDrop}
            //onDragEnter={onDragEnter}
            //  onDragStart={onDragEnter}
            treeData={treeData}
        />
    )

}