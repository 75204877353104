/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-13 10:31:17
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-17 13:32:03
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axGet, axPost } from '../../lib/axRequest'
import { setUserType } from '../user/userSlice'
import { message } from 'antd'
//import { fetchCount } from './counterAPI';

const initialState = {
    svnUrl: '',
    serverUrl: '',
    helpUrl: '',
    teamName: 'Axhub',
    chromeSecretKey: '',
    visitorPassword: '',
    teammatePassword: '',
    ipWhitelist: '',
    hasTeammatePW: false,
    hasVisitorPW: false,
    showSettingModal: false,
    updatingConfig: false,
    hasAdminAuth: false,
};


export const getConfig = createAsyncThunk(
    'config/getConfig',
    async (config, { dispatch }) => {
        const response = await axGet('/api/config/')
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            dispatch(setUserType(response.data.userType))
            return response.data;
        } else {
            throw '获取配置失败'
        }

    }
)

export const updateConifg = createAsyncThunk(
    'config/updateConifg',
    async (setting) => {
        const response = await axPost('/api/config/', setting)
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            message.success('更新成功，需要重启服务器才生效')
            return setting
        } else {
            throw '更新配置失败'
        }

    }
)

export const verify = createAsyncThunk(
    'config/verify',
    async (adPassword, { dispatch }) => {
        const response = await axPost('/api/config/verify', { adPassword })
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            dispatch(setUserType(3))
            return response.data
        } else {
            throw '认证失败'
        }

    }
)

export const generateUsertoken = createAsyncThunk(
    'config/generateUsertoken',
    async () => {
        const response = await axPost('/api/config/usertoken')
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            message.success('生成成功，需要重启服务器才生效')
            return response.data
        } else {
            throw '生成失败'
        }

    }
)


export const configSlice = createSlice({
    name: 'config',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action) => {
        //     state.value += action.payload;
        // },
        showSettingModal: (state, action) => {
            state.showSettingModal = action.payload
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getConfig.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
            })
            .addCase(updateConifg.pending, (state, action) => {
                state.updatingConfig = true
            })
            .addCase(updateConifg.fulfilled, (state, action) => {
                action.payload.updatingConfig = false
                Object.assign(state, action.payload)
            })
            .addCase(updateConifg.rejected, (state, action) => {
                state.updatingConfig = false
            })
            .addCase(verify.fulfilled, (state, action) => {
                action.payload.hasAdminAuth = true
                Object.assign(state, action.payload)
            })
            .addCase(generateUsertoken.fulfilled, (state, action) => {
                state.chromeSecretKey = action.payload
            })
    },
});

export const { showSettingModal } = configSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state) => state.counter.value;
// showProjectModal: (state, action) => {
//     state.showProjectModal = action.payload
// },
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

export default configSlice.reducer
