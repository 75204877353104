/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-19 22:25:10
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-17 16:56:49
 */
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Form, Input, Button, Layout } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { axPost } from '../../lib/axRequest'
import './Validation.css'
//import moment from 'moment'
//import './FolderModal.css'
const FormItem = Form.Item


export const Validation = ({ path }) => {

    const [loading, setLoading] = useState(false)
    const { teamName } = useSelector(state => state.config)

    const [form] = Form.useForm()
    const { validateFields } = form


    const handleSubmit = async () => {

        let data = await validateFields()
        data.path = path

        setLoading(true)

        await axPost('/api/project/verify/', data, false,
            (res) => {
                window.location.href = '/pro/' + path
            },
            () => {
                setLoading(false)
            })
    }

    // const formItemLayout = {
    //    // labelCol: { span: 6 },
    //     wrapperCol: { span: 14 },
    // }

    return (
        <Layout className="body">
            <div className=" account-card validation-card">
                <h1 className="account-title">{teamName || 'Axhub'}</h1>
                <Form className="account-form" form={form} >
                    <FormItem
                        //label="访问密码"
                        name="password"
                        rules={[{ required: true, message: '请输入访问密码' }]}
                        hasFeedback={true} >
                        <Input prefix={<LockOutlined />} placeholder="请输入访问密码" onPressEnter={handleSubmit} />
                    </FormItem>
                    <FormItem>
                        <Button type="primary" size="large" className="account-button" loading={loading} onClick={handleSubmit} >
                            {loading ? '验证中' : '验证'}
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </Layout>
    )
}


//export default Validation




