/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-19 22:25:10
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-25 23:17:53
 */
import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Form, Modal, Input, Tooltip, Button, Radio } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { updateProject, showProjectModal } from './projectSlice'
import { axGet, axPost } from '../../lib/axRequest'
import moment from 'moment'
import './ProjectModal.css'
const FormItem = Form.Item
const RadioGroup = Radio.Group


const ProjectModal = () => {
    //{ form, visible, loading, project, handleOk, handleCancel, onChange }

    const project = useSelector(state => state.project.selectedProject)
    const loading = useSelector(state => state.project.updatingProject)
    const visible = useSelector(state => state.project.showProjectModal)
    const folder = useSelector(state => state.folder.activedFolder)
    // const folderId = useRef(0)
    // folderId.current = folder.fid

    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { setFieldsValue, validateFields, resetFields } = form

    useEffect(() => {
        setFieldsValue(project)
    }, [project])

    let isCreating = typeof project.pid === 'undefined'
    let pid = isCreating ? 0 : project.pid


    const handleCancel = () => {
        dispatch(showProjectModal({ visible: false }))
    }

    const isExisted = async (type, value, callback) => {


        try {
            let params = {}
            params[type] = value
            let response = await axGet('/api/project/isExisted/', params)
            if (response.code === 0 && response.data.isExisted) {
                callback('项目' + (type === 'path' ? '目录' : '名称') + '已经存在')
            } else {
                callback()
            }

        } catch (error) {
            callback()
        }

    }

    const namevalidator = (rule, value, callback) => {

        let reg = /^[\u4e00-\u9fa5||\w||.||\s]{2,120}$/
        if (!reg.test(value)) {
            callback('需为中文、字母、数字、_或 .，长度为2至120')
        } else if (value !== project.name) {
            isExisted('name', value, callback)
        } else {
            callback()
        }


    }

    const pathvalidator = (rule, value, callback) => {

        if (value === '' && isCreating) callback()
        let reg = /^[\u4e00-\u9fa5||\w||.]{3,16}$/
        if (!reg.test(value)) {
            callback('需为中文、字母、数字、_或 .，长度为3至30')
        } else if (value !== project.path) {
            isExisted('path', value, callback)
        } else {
            callback()
        }
    }

    const passwordvalidator = (rule, value, callback) => {
        let reg = /^[^\u4e00-\u9fa5]{6,32}$/
        if (!reg.test(value) && value !== '') {
            callback('密码需为6-32位字母、数字或特殊字符')
        } else {
            callback()
        }
    }

    const handleSubmit = async () => {

        try {
            const data = await form.validateFields()
            data.isAutogenerate = Number(data.isAutogenerate || 1)
            if (!isCreating
                && data.name === project.name
                && data.path === project.path
                && data.software === project.software
                && data.password === project.password
                && data.webhook === project.webhook
            ) {
                handleCancel()
            } else {
                data.pid = pid
                data.fid = folder.fid
                dispatch(updateProject(data))
            }

        } catch (errorInfo) {
            console.error('Failed:', errorInfo);
        }
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    }

    return (
        <Modal
            forceRender
            visible={visible}
            title={(isCreating ? "新建项目" : "项目设置") + (folder.fid > 0 ? ' - ' + folder.name : '')}
            wrapClassName="vertical-center-modal"
            onCancel={() => {
                handleCancel()
                resetFields()
            }}
            afterClose={() => {
                handleCancel()
                resetFields()
            }}
            width={640}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    {loading ? '提交中' : '提交'}
                </Button>
            ]}
        >
            <Form className="projectForm" form={form} >
                <FormItem
                    label="项目名称"
                    name="name"
                    rules={[{ validator: namevalidator }]}
                    validateTrigger="onBlur"
                    initialValue={isCreating ? '' : project.name}
                    className={!isCreating && project.name !== '' ? 'edit-project-name' : 'create-project-name'}
                    hasFeedback={true}
                    extra={!isCreating && project.name !== '' ? '更改后，需在Axure内使用新SVN地址重新获取团队项目' : ''}
                    {...formItemLayout}
                >
                    <Input placeholder="请输入，如：微信V6.7.1" onPressEnter={handleSubmit} />
                </FormItem>
                <FormItem
                    label={(
                        <span>
                            URL目录&nbsp;
                            <Tooltip title="html 原型访问的 url 目录">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    )}
                    name="path"
                    rules={[{ validator: pathvalidator }]}
                    validateTrigger="onBlur"
                    initialValue={isCreating ? '' : project.path}
                    className="project-path"
                    hasFeedback={true}
                    {...formItemLayout} >
                    <Input placeholder={isCreating ? '选填，不建议使用中文' : '不建议使用中文'} onPressEnter={handleSubmit} />
                </FormItem>
                <FormItem
                    label={(
                        <span>
                            访问密码&nbsp;
                            <Tooltip title="设置后访问 html 链接需要输入密码">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    )}
                    name="password"
                    rules={[{ validator: passwordvalidator }]}
                    validateTrigger="onBlur"
                    initialValue={isCreating ? '' : project.password}
                    className="project-path"
                    hasFeedback={true}
                    {...formItemLayout} >
                    <Input placeholder="选填，需 6 位以上" onPressEnter={handleSubmit} />
                </FormItem>
                {isCreating ? null : <FormItem
                    label={(
                        <span>
                            webhook&nbsp;
                            <Tooltip title="更新原型时将会发送通知消息">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    )}
                    name="webhook"
                    //rules={[{ validator: passwordvalidator }]}
                    validateTrigger="onBlur"
                    initialValue={isCreating ? '' : project.webhook}
                    className="project-path"
                    hasFeedback={true}
                    {...formItemLayout} >
                    <Input placeholder="选填，目前只支持飞书群机器人" onPressEnter={handleSubmit} />
                </FormItem>}
                <FormItem>
                    <FormItem
                        label="软件版本"
                        name="software"
                        initialValue={isCreating ? 0 : project.software || 0}
                        //extra="Axure RP9 不支持 svn 协作"
                        {...formItemLayout}
                    >
                        <RadioGroup>
                            <Radio value={0}>AxureRP 8</Radio>
                            <Radio value={1}>AxureRP 9</Radio>
                            <Radio value={2}>AxureRP 10</Radio>
                        </RadioGroup>
                    </FormItem>
                </FormItem>

                {isCreating ? null : <FormItem
                    label='创建时间'
                    {...formItemLayout}>
                    {moment(project.createTime).format('YYYY-MM-DD HH:mm')}
                </FormItem>}
            </Form>
        </Modal>
    )
}


export default ProjectModal



// const mapStateToProps = (state, ownProps) => ({
//     visible: state.projectModal.visible,
//     loading: state.projectModal.loading,
//     project: state.projectModal.project
// })

// const mapDispatchToProps = (dispatch, ownProps) => ({

//     handleOk: (pid, project) => {
//         (pid === 0) ? dispatch(createProject(project)) : dispatch(updateProject(pid, project))
//     },
//     handleCancel: () => {
//         dispatch(showModal(false))
//     },
//     onChange: (item) => {
//         console.log(item)
//     }

// })




