/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-17 19:47:58
 * @LastEditors: Lintendo
 * @LastEditTime: 2021-11-07 22:25:26
 */
import React, { useState, useEffect, useRef } from 'react'

// const getScrollParent = (node) => {
//     if (!node || node.parentNode === document.documentElement) {
//         return null
//     }
//     const parentNode = node.parentNode
//     if (parentNode.scrollHeight > parentNode.clientHeight
//         || parentNode.scrollWidth > parentNode.clientWidth
//     ) {
//         return parentNode
//     }
//     return getScrollParent(parentNode)
// }


const ScrollList = (props) => {
    const { children, hasMore, loading, loadFun, page } = props
    const currentPage = useRef(0)
    const loadRef = useRef(null)
    const listRef = useRef(null)
    const scrollParent = useRef(null)

    currentPage.current = page

    // if(isReverse){
    //     page.current = 1
    //     //loadFun(page.current++)
    // }

    useEffect(() => {
        if (!scrollParent.current) {
            scrollParent.current = listRef.current.parentNode
        }
        scrollParent.current.addEventListener('scroll', scrollHandler)
        //loadFun()
    }, [])

    useEffect(() => {

        if (scrollParent.current && currentPage.current === 1) {
            scrollParent.current.scrollTop = 0
        }
        // if (scrollParent) {
        //     console.log(scrollParent.clientHeight)
        // }
        // if (scrollParent.current && scrollParent.current.clientHeight > pageHeight * page.current) {
        //     loadFun(page.current++)
        // }
        if (!hasMore) {
            scrollParent.current.removeEventListener('scroll', scrollHandler)
        }
    })

    const scrollHandler = () => {

        if (hasMore && loadRef.current) {
            let rect = loadRef.current.getBoundingClientRect()

            let visible = (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
            )

            //console.log('page:', page, visible)

            if (visible) {
                loadFun()
            }
        }

    }


    return (
        <div onScroll={scrollHandler} ref={listRef} >
            {children}
            {!loading && hasMore && <div ref={loadRef}></div>}
        </div>
    )


}

export default ScrollList