/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-10 19:15:51
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-16 22:32:48
 */
import React from 'react'
import { useLocation } from 'react-router'
import { Navbar } from './features/navbar/Navbar'
import { Sidebar } from './features/sidebar/Sidebar'
import { ProjectList } from './features/projects/Projects'
import { Validation } from './features/projects/Validation'
import { Layout } from 'antd'
import './App.css'

const { Header, Footer, Sider, Content } = Layout

const App = () => {

  const useQuery = new URLSearchParams(useLocation().search)
  const path = useQuery.get('path')

  return (
    path ?
      <Validation path={path} />
      :
      <Layout>
        <Header className="header" > <Navbar /> </Header>
        <Layout className="body">
          <Sider className="sider" width="200"> <Sidebar /></Sider>
          <Content className="project-list"><ProjectList /></Content>
        </Layout>
      </Layout>
  );
}

export default App;
