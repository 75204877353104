/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-24 20:54:50
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-09 23:07:04
 */
import React from 'react'
import { Form, Modal, Button, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
//import { showAccountModal } from '../navbar/configSlice'
import { showAccountModal, login } from '../user/userSlice'
const FormItem = Form.Item
//import './Folder.css'

const AccountModal = () => {

    const loading = useSelector(state => state.user.logining)
    const visible = useSelector(state => state.user.showAccountModal)
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const handleCancel = () => {
        dispatch(showAccountModal(false))
    }

    const handleSubmit = async () => {
        try {
            const data = await form.validateFields()
            dispatch(login(data.password))
        } catch (errorInfo) {
            console.error('Failed:', errorInfo);
        }
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    }

    return (
        <Modal
            forceRender
            visible={visible}
            title="权限不足"
            wrapClassName="vertical-center-modal"
            onCancel={handleCancel}
            afterClose={handleCancel}
            width={640}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    {loading ? '提交中' : '提交'}
                </Button>
            ]}
        >
            <Form className="projectForm" form={form} >
                <FormItem
                    label="验证密码"
                    name="password"
                    {...formItemLayout}
                >
                    <Input placeholder="请输入访客或团队成员密码" onPressEnter={handleSubmit} />
                </FormItem>
            </Form>
        </Modal>
    )

}

export default AccountModal