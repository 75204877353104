/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-10 23:42:48
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-17 16:48:30
 */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Button, message } from 'antd'
import { InboxOutlined, SettingOutlined, InfoCircleOutlined } from '@ant-design/icons'
//import { SmileOutlined } from '@ant-design/icons';
import { getConfig, showSettingModal } from './configSlice';
import CopyToClipboard from 'react-copy-to-clipboard'
import './Navbar.css'
//import styles from './Navbar.module.css';
import SettingModal from './SettingModal'
import AccountModal from '../user/AccountModal'

import { Search } from './Search'
//import { helpUrl } from '../config'
//console.log(styles)


export const Navbar = () => {

    const { teamName, svnUrl } = useSelector(state => state.config)

    const dispatch = useDispatch()

    useEffect(() => dispatch(getConfig()), [])

    const Logo = () => <a href="#" ><span>{teamName || ''}</span></a>

    return (
        <div className="navbar" >
            <SettingModal />
            <AccountModal />
            <div className="logo" ><Logo /></div>
            <div className="search" ><Search /></div>
            <div className="topnav" >
                <Tooltip title="svn 仓库地址" placement="left" >
                    {svnUrl ? <span className="help-btn" >
                        <CopyToClipboard text={svnUrl}
                            onCopy={(text) => {
                                message.success(<span>SVN 仓库地址复制成功，请到 Axure 里点击团队->从当前项目创建团队项目后填入 </span>)
                            }} >
                            <InboxOutlined />
                        </CopyToClipboard>
                    </span>
                        : null}
                </Tooltip>

                <Tooltip title="设置" placement="left" >
                    <span onClick={() => dispatch(showSettingModal(true))} className="help-btn" >
                        <SettingOutlined />
                    </span>
                </Tooltip>

                {/* <Tooltip title="帮助文档" placement="left" >
                    <a href={helpUrl} target="_blank" className="help-btn" >
                        <Icon type="question-circle-o" />
                    </a>
                </Tooltip> */}

                <Tooltip title="技术支持 Axhub" placement="left" >
                    <a href="https://axhub.im" target="_blank" className="help-btn" >
                        <InfoCircleOutlined />
                    </a>
                </Tooltip>

                {/* <Button size="large"
                onClick={() => {
                    window.open(goBackUrl, 'goback')
                }}>
                返回旧版
                </Button> */}
            </div>

        </div >
    )

}
