/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-24 20:54:38
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-17 13:31:36
 */
/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-13 10:31:17
 * @LastEditors: Lintendo
 * @LastEditTime: 2021-10-15 17:11:02
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axPost } from '../../lib/axRequest'
//import { fetchCount } from './counterAPI';

const initialState = {
    showAccountModal: false,
    logining: false,
    userType: 0,
}

export const login = createAsyncThunk(
    'user/login',
    async (password) => {
        const response = await axPost('/api/account/login', { password })
        if (response.code === 0) {
            window.location.reload()
        } else {
            throw '登录失败'
        }

    }
)

export const userSlice = createSlice({
    name: 'folder',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        showAccountModal: (state, action) => {
            state.showAccountModal = action.payload
        },
        setUserType: (state, action) => {
            state.userType = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.logining = true
            })
            .addCase(login.rejected, (state, action) => {
                state.logining = false
            })

    },
});


export const { showAccountModal, setUserType } = userSlice.actions
export default userSlice.reducer
