/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-19 22:25:10
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-17 16:46:13
 */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Form, Modal, Input, Tooltip, Button, InputNumber } from 'antd'
import { QuestionCircleOutlined  } from '@ant-design/icons'
import { updateFolder, showFolderModal } from './folderSlice'
import { axGet, axPost } from '../../lib/axRequest'
//import moment from 'moment'
//import './FolderModal.css'
const FormItem = Form.Item


const FolderModal = () => {
    //{ form, visible, loading, folder, handleOk, handleCancel, onChange }

    const folder = useSelector(state => state.folder.editedFolder)
    const loading = useSelector(state => state.folder.updatingFolder)
    const visible = useSelector(state => state.folder.showFolderModal)
    const parentFid = useSelector(state => state.folder.parentFid)

    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { setFieldsValue, validateFields, resetFields } = form

    useEffect(() => {
        setFieldsValue(folder)
    }, [folder])

    let isCreating = typeof folder.fid === 'undefined'
    let fid = isCreating ? 0 : folder.fid
    let parent = isCreating ? parentFid : folder.parent


    const handleCancel = () => {
        dispatch(showFolderModal({ visible: false }))
    }

    const isExisted = async (type, value, callback) => {
        try {
            let params = {}
            params[type] = value
            let response = await axGet('/api/folder/isExisted/', params)
            if (response.code === 0 && response.data.isExisted) {
                callback('文件夹' + (type === 'path' ? '目录' : '名称') + '已经存在')
            } else {
                callback()
            }

        } catch (error) {
            callback()
        }

    }

    const namevalidator = (rule, value, callback) => {

        let reg = /^[\u4e00-\u9fa5||\w||.||\s]{2,120}$/
        if (!reg.test(value)) {
            callback('需为中文、字母、数字、_或 .，长度为2至120')
        } else if (value !== folder.name) {
            isExisted('name', value, callback)
        } else {
            callback()
        }


    }

    const pathvalidator = (rule, value, callback) => {

        if (value === '' && isCreating) callback()
        let reg = /^[\u4e00-\u9fa5||\w||.]{3,16}$/
        if (!reg.test(value)) {
            callback('需为中文、字母、数字、_或 .，长度为3至30')
        } else if (value !== folder.path) {
            isExisted('path', value, callback)
        } else {
            callback()
        }
    }

    const handleSubmit = async () => {

        try {
            const data = await validateFields()
            data.isAutogenerate = Number(data.isAutogenerate || 1)
            if (!isCreating && data.name === folder.name && data.path === folder.path) {
                handleCancel()
            } else {
                data.fid = fid
                data.parent = parent
                dispatch(updateFolder(data))
            }

        } catch (errorInfo) {
            console.error('Failed:', errorInfo);
        }
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    }

    return (
        <Modal
            forceRender
            visible={visible}
            title={isCreating ? (parentFid ? "新建子文件夹" : "新建文件夹") : "文件夹设置"}
            wrapClassName="vertical-center-modal"
            onCancel={() => {
                handleCancel()
                resetFields()
            }}
            afterClose={() => {
                handleCancel()
                resetFields()
            }}
            width={640}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    {loading ? '提交中' : '提交'}
                </Button>
            ]}
        >
            <Form className="folderForm" form={form} >
                <FormItem
                    label="文件夹名称"
                    name="name"
                    rules={[{ validator: namevalidator }]}
                    validateTrigger="onBlur"
                    initialValue={isCreating ? '' : folder.name}
                    className={!isCreating && folder.name !== '' ? 'edit-folder-name' : 'create-folder-name'}
                    hasFeedback={true}
                    {...formItemLayout}
                >
                    <Input placeholder="请输入，如：微信" onPressEnter={handleSubmit} />
                </FormItem>
                <FormItem
                    label={(
                        <span>
                            url 目录&nbsp;
                            <Tooltip title="文件夹访问的 url 目录">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    )}
                    name="path"
                    rules={[{ validator: pathvalidator }]}
                    validateTrigger="onBlur"
                    initialValue={isCreating ? '' : folder.path}
                    className="folder-path"
                    hasFeedback={true}
                    {...formItemLayout} >
                    <Input placeholder={isCreating ? '选填，不建议使用中文' : '不建议使用中文'} onPressEnter={handleSubmit} />
                </FormItem>
            </Form>
        </Modal>
    )
}


export default FolderModal



// const mapStateToProps = (state, ownProps) => ({
//     visible: state.folderModal.visible,
//     loading: state.folderModal.loading,
//     folder: state.folderModal.folder
// })

// const mapDispatchToProps = (dispatch, ownProps) => ({

//     handleOk: (fid, folder) => {
//         (fid === 0) ? dispatch(createFolder(folder)) : dispatch(updateFolder(fid, folder))
//     },
//     handleCancel: () => {
//         dispatch(showModal(false))
//     },
//     onChange: (item) => {
//         console.log(item)
//     }

// })




