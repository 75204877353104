/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-16 22:58:27
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-23 22:15:43
 */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Table, Dropdown, Menu, message, Tooltip, Modal, Button } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import moment from 'moment'
import { useLocation, useParams } from 'react-router'
import { getProjects, deleteProject, removeLocks, showProjectModal, updateProject, fileProjects, removeProjects } from './projectSlice'
import { showAccountModal } from '../user/userSlice'
import { showMoveModal } from '../folders/folderSlice'
import ScrollList from '../scrolllist/ScrollList'
import { BarsOutlined, ShareAltOutlined, FileOutlined, SwapOutlined, DeleteOutlined, FileDoneOutlined, DisconnectOutlined, FolderOpenOutlined, SettingOutlined, LinkOutlined } from '@ant-design/icons'

import './Projects.css'
const { Content } = Layout
const { Column } = Table
const { confirm } = Modal
const getPopupContainer = () => document.body


const Pages = ({ path, pages, keyword }) => {

    const PageName = ({ text }) => {
        let splitedName = text.split(keyword, 2)
        if (splitedName.length === 2) {
            return <span className="page-name" ><FileOutlined /> {splitedName[0]}<span className="highlight" >{keyword}</span>{splitedName[1]}</span>
        } else {
            return <span className="page-name" ><FileOutlined /> {text}</span>
        }
    }

    const PageContent = ({ text }) => {
        let splitedContent = text.split(keyword, 2)

        if (splitedContent.length === 2) {
            return <span className="page-content" > {splitedContent[0]}<span className="highlight" >{keyword}</span>{splitedContent[1]}</span >
        } else {
            return <span className="page-content" >{text}</span>
        }
    }

    if (pages) {
        return (
            <ul className="pages" >
                {
                    pages.map(page => (
                        <li key={page.pid} ><a target="_blank" href={path + '/#g=1&p=' + page.file.replace('.html', '')} >
                            <PageName text={page.name} /><PageContent text={page.content} />
                        </a></li>
                    ))
                }
            </ul>
        )
    }
}

const CopyAxureSvn = ({ svn, svnUrl }) => {

    const cutStr = (str, len) => {
        let realLength = 0
        let strCut = ''
        let char

        for (let i = 0; i < str.length; i++) {
            char = str.charAt(i)
            realLength += escape(char).length > 4 ? 2 : 1
            strCut = strCut.concat(char)

            if (realLength > len) {
                strCut = strCut.concat('...')
                return strCut
            }
        }

        return str
    }

    let svnPath = svnUrl + svn + '/'
    let svnName = cutStr(svn, 12)

    return (
        <Tooltip placement="top" title="复制项目SVN地址" mouseEnterDelay={0.4} mouseLeaveDelay={0} getPopupContainer={getPopupContainer} >
            <CopyToClipboard text={svnPath}
                onCopy={(text) => {
                    message.success(<span>{svnName} SVN 地址复制成功，请到 Axure 里使用</span>)
                }} >
                <LinkOutlined />
            </CopyToClipboard>
        </Tooltip>
    )
}

const CopyUrl = ({ name, path, password }) => {
    return (
        <Tooltip placement="top" title="分享原型" mouseEnterDelay={0.4} mouseLeaveDelay={0} getPopupContainer={getPopupContainer} >
            <CopyToClipboard text={name + ' ' + window.location.protocol + '//' + window.location.host + '/pro/' + path + (password ? '（密码：' + password +'）': '')}
                onCopy={(text) => {
                    message.success(<span>分享信息复制成功</span>)
                }} >
                <ShareAltOutlined />
            </CopyToClipboard>
        </Tooltip>
    )
}

export const ProjectList = () => {


    const dispatch = useDispatch()
    let { userType, svnUrl, hasTeammatePW, serverUrl } = useSelector(state => state.config)
    const projects = useSelector(state => state.project.list)
    const { loading, hasMore, page } = useSelector(state => state.project)
    const folder = useSelector(state => state.folder.current)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const params = useParams()

    const nextPage = useRef(0)
    const keyword = useRef('')
    //const folderId = useRef(0)
    const path = useRef('')
    nextPage.current = page + 1
    //folderId.current = folder.fid
    path.current = params.folder || ''
    keyword.current = new URLSearchParams(useLocation().search).get('keyword')

    useEffect(() => {
        nextPage.current = 1
        setSelectedRowKeys([])
        loadFun()
    }, [keyword.current, path.current])


    useEffect(() => {
        let newExpandedRowKeys = []
        if (keyword.current) {
            projects.forEach((project, index) => {
                if (project.pages && project.pages.length > 0) {
                    newExpandedRowKeys.push(project.pid)
                }
            })
            setExpandedRowKeys(newExpandedRowKeys)
        }
    }, [projects])


    if (!hasTeammatePW) {
        userType = 2
    }


    const expandedRowRender = (item) => {
        return (
            <Pages
                path={serverUrl + 'pro/' + item.path}
                pages={item.pages}
                keyword={keyword.current}
            />
        )
    }

    const onExpand = (expanded, project) => {
        if (expanded) {
            expandedRowKeys.push(project.pid)
        } else {
            let index = expandedRowKeys.indexOf(project.pid)
            expandedRowKeys.splice(index, 1)
        }
    }

    const setProject = (project) => {
        dispatch(showProjectModal({ visible: true, project }))
    }

    const login = () => {
        dispatch(showAccountModal(true))
    }

    const loadFun = () => {
        dispatch(getProjects({
            page: nextPage.current,
            keyword: keyword.current,
            path: path.current,
            //fid: folderId.current
        }))
    }

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const menu = (project) => {

        const deleteConfirm = () => {
            confirm({
                title: '是否要删除项目 ' + project.name + ' ?',
                content: '删除后将自动清空对应html文件和svn，且不可恢复',
                onOk: () => {
                    dispatch(deleteProject(project.pid))
                }
            })
        }

        const removeLocksConfirm = () => {
            confirm({
                title: '是否清除 ' + project.name + ' 所有签出?',
                content: '清除后可能会影响到已签出用户的编辑与提交',
                onOk: () => {
                    dispatch(removeLocks(project.pid))
                }
            })
        }

        const filedConfirm = () => {
            confirm({
                title: '是否要将项目 ' + project.name + ' 归档?',
                content: '将已经结束或者暂停的项目归档，之后也可以方便的查看和恢复',
                onOk: () => {
                    dispatch(updateProject({ pid: project.pid, isFiled: 1 }))
                }
            })
        }

        return (
            <Menu selectedKeys={[]} onClick={({ key }) => {

                if (userType < 2) {
                    return login()
                }

                switch (key) {
                    case '0':
                        //if (project.name) onGenerate(project.pid)
                        break
                    case '1':
                        project.isFiled ? dispatch(updateProject({ pid: project.pid, isFiled: 0 })) : filedConfirm()
                        break
                    case '2':
                        if (project.name) removeLocksConfirm()
                        break
                    case '3':
                        deleteConfirm()
                        break

                    default:

                }
            }} >
                {/* {project.software === 0 ?
                    <Menu.Item key="0" >
                        <span style={project.name ? {} : { color: 'rgba(0, 0, 0, 0.43)', cursor: 'not-allowed' }} >
                            <Icon type="sync" />生成html
                    </span>
                    </Menu.Item>
                    : null} */}
                <Menu.Item key="1">
                    <span><FolderOpenOutlined />{project.isFiled ? '取消归档' : '归档'}</span>
                </Menu.Item>
                {project.software === 0 && svnUrl ?
                    <Menu.Item key="2" >
                        <span style={project.name ? {} : { color: 'rgba(0, 0, 0, 0.43)', cursor: 'not-allowed' }} >
                            <DisconnectOutlined />清除签出
                        </span>
                    </Menu.Item>
                    : null}
                <Menu.Item key="3">
                    <span><DeleteOutlined />删除项目</span>
                </Menu.Item>
            </Menu>
        )
    }

    const BatchAction = () => {

        const onMove = () => {
            if (userType < 2) {
                return login()
            }
            dispatch(showMoveModal({
                visible: true,
                type: 'project',
                ids: selectedRowKeys
            }))
        }
        const onFile = () => {
            if (userType < 2) {
                return login()
            }
            confirm({
                title: '是否要归档选中的' + selectedRowKeys.length + '个项目?',
                content: '归档后项目将不在进行中显示',
                onOk: () => {
                    dispatch(fileProjects({
                        pids: selectedRowKeys,
                        path: path.current
                    }))
                }
            })
        }

        const onDelete = () => {
            if (userType < 2) {
                return login()
            }
            confirm({
                title: '是否要删除选中的' + selectedRowKeys.length + '个项目?',
                content: '删除后将自动清空对应html文件和svn，且不可恢复',
                onOk: () => {
                    dispatch(removeProjects({
                        pids: selectedRowKeys
                    }))
                }
            })
        }
        return (
            <div className='batch-action'>
                <Button size='small' icon={<SwapOutlined />} onClick={onMove} >移动</Button>
                <Button size='small' icon={<FileDoneOutlined />} onClick={onFile} >归档</Button>
                <Button size='small' icon={<DeleteOutlined />} onClick={onDelete} >删除</Button>
            </div>
        )
    }

    // const rowClassName = (item) => {

    //     if (keyword) {
    //         switch (item.pages && item.pages.length > 0) {
    //             case true:
    //                 return 'expanded-row'

    //             case false:
    //                 return 'close-row'

    //             default:
    //                 return 'normal-row'
    //         }


    //     }
    //     return null
    // }

    //const { children, hasMore, loading, loadFun, spin, page, pageHeight } = props
    return (
        <>
            {/* // <Layout className="content" > */}
            {/* <ProjectModal />
                <SettingModal />
                <AccountModal visible={true} /> */}
            {/* <Content className="list_header" ><ProjectHeader /></Content> */}

            {/* <Content style={{ overflow: 'hidden' }}> */}

            <ScrollList
                page={nextPage.current}
                loadFun={loadFun}
                hasMore={hasMore}
                loading={loading}
                className="project-list"
            >
                <Table
                    loading={loading}
                    dataSource={projects}
                    rowKey="pid"
                    pagination={false}
                    onRow={project => {
                        return {
                            onClick: event => { window.open(serverUrl + 'pro/' + project.path + '/', 'newWidow' + project.pid) }, // 点击行
                        };
                    }
                    }
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    expandable={keyword.current ? {
                        expandedRowKeys,
                        expandedRowRender,
                        rowExpandable: project => project.pages && project.pages.length > 0,
                        onExpand,
                    } : false}
                    //expandable={false}
                    //rowClassName={rowClassName}
                    //onExpand={ }
                    //showHeader={false}
                    //filters={filters}
                    className={"project-table" + (selectedRowKeys.length > 0 ? ' selected' : '')}
                    sticky={true}
                //getBodyWrapper={getBodyWrapper}

                >
                    <Column
                        title={selectedRowKeys.length > 0 ? <BatchAction /> : '项目名称'}
                        key="name"
                        width="40%"
                        render={
                            item => {
                                let name = (item.name === "") ? item.path : item.name
                                if (keyword.current && item.rank >= 3) {
                                    let splitedTitle = name.split(keyword.current, 2)
                                    if (splitedTitle.length === 2) {
                                        return <span>{splitedTitle[0]}<span className="highlight" >{keyword.current}</span>{splitedTitle[1]}</span >
                                    }
                                }

                                return <span>{name}</span>
                            }
                        }
                        className="project-name"
                    />
                    <Column
                        title={selectedRowKeys.length > 0 ? null : '更新时间'}
                        dataIndex="generateTime"
                        sorter={false}
                        key="generateTime"
                        width="25%"
                        render={item => item ? moment(item).format('YYYY-MM-DD HH:mm') : '暂无'}
                        className="project-time" />
                    <Column

                        key="action"
                        width="35%"
                        className="project-action"
                        render={
                            item => {
                                return (
                                    <div onClick={e => { e.stopPropagation() }} >
                                        <CopyUrl name={item.name} path={item.path} password={item.password} />
                                        {item.name && item.software === 0 && svnUrl ? <CopyAxureSvn svn={item.name} svnUrl={svnUrl} /> : null}
                                        <span onClick={() => { userType >= 2 ? setProject(item) : login() }}>
                                            <Tooltip placement="top" title="设置" mouseEnterDelay={0.4} mouseLeaveDelay={0} getPopupContainer={getPopupContainer} >
                                                <SettingOutlined />
                                            </Tooltip>
                                        </span>
                                        <Dropdown overlay={menu(item)} trigger={['click']} getPopupContainer={getPopupContainer} >
                                            <span className="ant-dropdown-link" href="#">
                                                <Tooltip placement="top" title="更多" mouseEnterDelay={0.4} mouseLeaveDelay={0} getPopupContainer={getPopupContainer}  >
                                                    <BarsOutlined />
                                                </Tooltip>
                                            </span>
                                        </Dropdown>
                                    </div>
                                )
                            }
                        }
                    />
                </Table >
            </ScrollList>
            {/* </Content> */}
            {/* // </Layout> */}
        </>

    )
}

// const ProjectList extends React.Component {

//     constructor(props) {
//         super(props)

//         this.scrollHandler = () => {

//             if (!this.props.isEnd && this.flag) {
//                 let rect = this.flag.getBoundingClientRect()

//                 let visible = (
//                     rect.top >= 0 &&
//                     rect.left >= 0 &&
//                     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
//                     rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
//                 )

//                 if (visible) {
//                     this.props.pagination.current++
//                     //this.props.pagination.pageSize = 20         
//                     this.props.onScroll(this.props.pagination, this.props.filters, this.props.keyword)
//                 }
//             }

//         }

//         this.SpinDiv = () => {

//             let element
//             if (!this.props.isEnd) {
//                 element = this.props.loadingMore ? <div className="spin" ><Spin /><span>加载中...</span></div> : <div ref={node => { this.flag = node }} ></div>
//             } else {
//                 element = <div className="spin" ><span>已经没有更多数据了...</span></div>
//             }
//             return element
//         }
//     }

//     componentDidUpdate() {
//         if (this.props.pagination.current === 1) {
//             this.list.scrollTop = 0
//             if (this.list.clientHeight > this.props.pagination.pageSize * 54) {
//                 this.props.pagination.current++
//                 this.props.onScroll(this.props.pagination, this.props.filters, this.props.keyword)
//             }
//         }

//     }
//     componentDidMount() {
//         if (this.list.clientHeight > this.props.pagination.pageSize * 54) {
//             this.props.onScroll(this.props.pagination, this.props.filters, this.props.keyword)
//         }
//     }


//     render() {
//         return (
//             <Layout className="content" >
//                 <ProjectModal />
//                 <SettingModal />
//                 <AccountModal visible={true} />      
//                 <Content className="list_header" ><ProjectHeader /></Content>
//                 <div className="list_content" onScroll={() => this.scrollHandler()} ref={node => this.list = node} id="project_list" >
//                     <Content style={{ overflow: 'hidden' }}>
//                         <Projects />
//                         <this.SpinDiv />
//                     </Content>
//                 </div>
//             </Layout>
//         )
//     }

// }



// const mapStateToProps = (state, ownProps) => ({
//     pagination: state.projects.pagination,
//     keyword: state.projects.keyword,
//     loadingMore: state.projects.loadingMore,
//     isEnd: state.projects.isEnd,
//     filters: state.projects.filters
// })

// const mapDispatchToProps = (dispatch, ownProps) => ({

//     onScroll: (pagination, filters, keyword) => {
//         dispatch(getProjects(pagination, filters, {}, keyword, true))
//     },
//     handleSetting: () => {
//         dispatch(showModal(true))
//     }
// })

export default ProjectList