/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-10 19:15:51
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-08 22:18:24
 */
import React from 'react';
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
//import { store } from './app/store';
import { Provider } from 'react-redux';
//import * as serviceWorker from './serviceWorker';
import { store, history } from './app/store'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router'

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
    <Provider store={store()}>
      <ConnectedRouter history={history}>
        <>
          <Switch>
            <Route exact path="/" render={() => (<App />)} />
            <Route path="/:folder" render={() => (<App />)} /> 
            {/* <Route exact path="/test" render={() => (<Counter />)} /> */}
            <Route render={() => (<div>404</div>)} />
          </Switch>
        </>
      </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
