/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-24 20:54:38
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-17 21:47:40
 */
/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-13 10:31:17
 * @LastEditors: Lintendo
 * @LastEditTime: 2021-10-15 17:11:02
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axGet, axPost, axDelete } from '../../lib/axRequest'
import { showAccountModal } from '../user/userSlice'
import { message } from 'antd'
//import { fetchCount } from './counterAPI';

const initialState = {
    list: [],
    // page: 0,
    loading: false,
    // hasMore: true,
    activedFolder: { title: '正在进行', fid: -2, key: -2, path: 'working' },
    selectedFolder: {},
    editedFolder: {},
    parentFid: 0,
    showFolderModal: false,
    updatingFolder: false,
    showMoveModal: false,
    moving: false,
    movedIds: [],
    //moveType: 'project'
}


// export const getFolder = createAsyncThunk(
//     'folder/getFolder',
//     async ({ fid, path }) => {
//         const response = await axGet('/api/folder/', {
//             fid,
//             path,
//             level: 0 
//         })
//         // The value we return becomes the `fulfilled` action payload
//         if (response.code === 0) {

//             return {
//                 list: response.data,
//                 fid,
//                 path
//             }

//         } else {
//             throw '获取文件夹信息失败'
//         }

//     }
// )

export const getFolders = createAsyncThunk(
    'folder/getFolders',
    async ({ fid, path, type }, { dispatch }) => {
        const response = await axGet('/api/folder/', {
            fid,
            path,
        })
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            return {
                list: response.data.list,
                folder: response.data.folder,
                type
            }

        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '获取文件夹列表失败'
        }
    }
)

export const updateFolder = createAsyncThunk(
    'folder/updateFolder',
    async (folder, { dispatch }) => {
        let isCreated = !Boolean(folder.fid)
        let url = '/api/folder/' + (isCreated ? '' : folder.fid)
        const response = await axPost(url, folder)
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            dispatch(showFolderModal({ visible: false }))

            message.success(folder.fid ? '更新成功' : '创建成功')
            Object.assign(folder, response.data)
            return {
                folder: isCreated ? response.data : folder,
                isCreated
            }
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)

export const deleteFolder = createAsyncThunk(
    'folder/deleteFolder',
    async (fid, { dispatch }) => {
        const hideMessage = message.loading('删除中...', 0)
        const response = await axDelete('/api/folder/' + fid)
        hideMessage()
        if (response.code === 0) {
            message.success('删除成功')
            return fid
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)


export const moveFolder = createAsyncThunk(
    'folder/moveFolder',
    async (folder, { dispatch }) => {
        let url = '/api/folder/move'
        const response = await axPost(url, {
            fid: folder.fid,
            parent: folder.parent,
            weight: folder.weight,
            sort: folder.sort,
        })
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            //thunkAPI.dispatch(showFolderModal({ visible: false }))
            //Object.assign(folder, response.data)
            return {
                folder,
                list: response.data.list,
            }
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '移动失败'
        }

    }
)

// export const moveProjects = createAsyncThunk(
//     'folder/moveFolders',
//     async ({ pids, fid, activedFid }, thunkAPI) => {

//         let url = '/api/project/batchMove'
//         thunkAPI.dispatch(setMoving(true))
//         const response = await axPost(url, { pids, fid })
//         // The value we return becomes the `fulfilled` action payload
//         thunkAPI.dispatch(setMoving(false))
//         if (response.code === 0) {
//             thunkAPI.dispatch(showMoveModal({ visible: false }))
//             message.success('移动成功')
//             return {
//                 fid,
//                 activedFid,
//                 pids
//             }
//         } else {
//             throw '操作失败'
//         }

//     }
// )

export const folderSlice = createSlice({
    name: 'folder',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action) => {
        //     state.value += action.payload;
        // },
        showFolderModal: (state, action) => {
            state.showFolderModal = action.payload.visible
            state.editedFolder = action.payload.folder || {}
            state.parentFid = action.payload.parent || 0

        },
        showMoveModal: (state, action) => {
            state.showMoveModal = action.payload.visible
            state.movedIds = action.payload.ids || state.movedIds
            state.moveType = action.payload.type || state.moveType
        },
        setMoving: (state, action) => {
            state.moving = action.payload
        },
        setSelectedFolder: (state, action) => {
            state.selectedFolder = action.payload
        },
        setActivedFolder: (state, action) => {
            state.activedFolder = action.payload
        },
        // setFolder: (state, action) => {

        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getFolders.pending, (state) => {
                state.loading = true
            })
            .addCase(getFolders.fulfilled, (state, action) => {

                state.loading = false
                let folder = action.payload.folder
                let requestFid = folder.fid || 0

                if (requestFid === 0) {
                    state.list = action.payload.list
                } else {

                    let parentFid = action.payload.list.length > 0 ? action.payload.list[0]['parent'] : requestFid

                    if (folder.fid === parentFid) {
                        folder.children = action.payload.list
                    }

                    state.list = state.list.map((item) => {

                        let node = { ...item }
                        if (item.fid === parentFid) {
                            node.children = action.payload.list
                            if (action.payload.type) {
                                state[action.payload.type + 'Folder'] = folder
                            }
                        }

                        return node
                    })
                }
            })
            .addCase(getFolders.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(updateFolder.pending, (state) => {
                state.updatingFolder = true
            })
            .addCase(updateFolder.fulfilled, (state, action) => {
                state.updatingFolder = false
                let folder = action.payload.folder

                if (action.payload.isCreated) {
                    //新建文件夹
                    if (folder.parent === 0) {
                        state.list.push(folder)
                    } else {
                        state.list = state.list.map((item) => {
                            let node = { ...item }
                            if (item.fid === folder.parent) {
                                node.children = node.children || []
                                node.children.push(folder)
                            }
                            return node
                        })
                    }
                } else {
                    //更新文件夹
                    state.list = updateTreeData(state.list, folder)
                }

            })
            .addCase(updateFolder.rejected, (state, action) => {
                state.updatingFolder = false
            })
            .addCase(deleteFolder.fulfilled, (state, action) => {
                state.list.some((folder, index) => {
                    if (folder.fid === action.payload) {
                        state.list.splice(index, 1)
                        return true
                    }
                    return false
                })
            })
            .addCase(moveFolder.fulfilled, (state, action) => {
                let { list, folder } = action.payload
                let newlist = state.list.concat()
                //删除原来的位置
                newlist = deleteTreeData(newlist, folder.originFolder)

                //插入到列表
                let node = { ...folder.originFolder }
                node.weight = folder.weight
                node.parent = folder.parent
                newlist = insertTreeData(newlist, folder.parent, folder.newPos, node)

                //更新权重
                if (list.length > 0) {
                    if (folder.parent > 0) {
                        newlist[folder.parentPos]['children'] = newlist[folder.parentPos]['children'] || []
                        newlist[folder.parentPos]['children'].map((item, index) => {
                            newlist[folder.parentPos]['children'][index]['weight'] = list[index].weight
                        })
                    } else {
                        newlist.map((item, index) => {
                            newlist[index].weight = list[index].weight
                        })
                    }
                }

                state.list = newlist
            })
    },
});


export const { showFolderModal, showMoveModal, setMoving, setSelectedFolder, setActivedFolder } = folderSlice.actions
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const updateFolder = (folder) => async (dispatch, getState) => {
//     // const currentValue = selectCount(getState());
//     // if (currentValue % 2 === 1) {
//     //     dispatch(incrementByAmount(amount));
//     // }
//     let url = '/api/folder/'
//     if (folder.fid) {
//         url += fid
//     }
//     const response = await axPost(url, folder)
//     if (response.code === 0) {
//         message.success(folder.fid ? '更新成功' : '创建成功')
//         return response.data
//     } else {
//         throw '操作失败'
//     }

// };


const updateTreeData = (list, folder) => {

    return list.map((node) => {

        if (node.fid === folder.fid) {
            Object.assign(node, folder)
            return node
        }

        if (node.fid === folder.parent) {
            return { ...node, children: updateTreeData(node.children, folder) }
        }

        return { ...node }
    })
}

const deleteTreeData = (tree, folder) => {

    //let newTree = tree.filter(node => node.fid === folder.fid)

    tree.some((node, index) => {
        if (node.fid === folder.fid) {
            tree.splice(index, 1)
            return true
        }
        if (node.fid === folder.parent) {
            tree[index]['children'] = deleteTreeData(node.children, folder)
        }
    })

    return tree

}

const insertTreeData = (tree, parent, postion, folder) => {

    //let newTree = tree.filter(node => node.fid === folder.fid)

    //console.log(tree, parent, postion, folder)

    tree.some((node, index) => {
        if (node.parent === parent) {
            tree.splice(postion, 0, folder)
            return true
        }
        if (node.fid === parent) {
            tree[index]['children'] = tree[index]['children'] || []
            tree[index]['children'].splice(postion, 0, folder)
            return true
        }
    })

    return tree

}

// const deleteTreeData = async (list, folder, position = '') => {

//     if (position) {
//         let posArr = position.split('-')

//         if (posArr.length === 1) {
//             list.splice(posArr[0], 1)
//         } else {
//             return deleteTreeData
//         }


//         posArr.map((pos, index) => {
//             list[pos] = list[pos] || []
//             if (index + 1 === posArr.length) {
//                 list.splice(pos, 1)
//             } else {
//                 list[pos]['children'] = list[pos]['children'] || []
//                 deleteTreeData(list[pos]['children'], folder)
//             }
//         })
//     } else {
//         return list.map((node) => {

//             if (node.fid === folder.fid) {
//                 Object.assign(node, folder)
//                 return node
//             }

//             if (node.fid === folder.parent) {
//                 return { ...node, children: deleteTreeData(node.children, folder) }
//             }

//             return { ...node }
//         })
//     }


// }

export default folderSlice.reducer;
