/*
 * @Description: 
 * @Author: Lintendo
 * @Date: 2021-10-13 10:31:17
 * @LastEditors: Lintendo
 * @LastEditTime: 2022-03-16 00:34:30
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axGet, axPost, axDelete } from '../../lib/axRequest'
import { showMoveModal, setMoving } from '../folders/folderSlice'
import { showAccountModal } from '../user/userSlice'
import { message } from 'antd'
//import { fetchCount } from './counterAPI';

const initialState = {
    list: [],
    page: 0,
    loading: false,
    hasMore: true,
    selectedProject: {},
    showProjectModal: false,
    updatingProject: false,

}


export const getProjects = createAsyncThunk(
    'project/getProjects',
    async ({ page, keyword, path }) => {
        let isFiled = -1

        if (path === 'working' || !path) {
            isFiled = 0
            path = ''
        } else if (path === 'all') {
            path = ''
        }

        const response = await axGet('/api/project/', {
            page,
            keyword,
            path,
            isFiled,
        })
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            let data = response.data
            data.page = page
            return data;
        } else {
            throw '获取项目列表失败'
        }

    }
)


export const updateProject = createAsyncThunk(
    'project/updateProject',
    async (project, { dispatch }) => {
        let isCreated = !Boolean(project.pid)
        let url = '/api/project/' + (isCreated ? '' : project.pid)
        const response = await axPost(url, project)
        // The value we return becomes the `fulfilled` action payload
        if (response.code === 0) {
            dispatch(showProjectModal({ visible: false }))
            message.success(project.pid ? '更新成功' : '创建成功')
            return {
                project: isCreated ? response.data : project,
                isCreated
            }
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)


export const moveProjects = createAsyncThunk(
    'project/moveProjects',
    async ({ pids, fid, activedFid }, { dispatch }) => {

        let url = '/api/project/batchMove'
        dispatch(setMoving(true))
        const response = await axPost(url, { pids, fid })
        // The value we return becomes the `fulfilled` action payload
        dispatch(setMoving(false))
        if (response.code === 0) {
            dispatch(showMoveModal({ visible: false }))
            message.success('移动成功')
            return {
                fid,
                activedFid,
                pids
            }
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)

//批量归档
export const fileProjects = createAsyncThunk(
    'project/fileProjects',
    async ({ pids, path }, { dispatch }) => {

        let url = '/api/project/batchFile'
        const hideMessage = message.loading('归档中...', 0)
        const response = await axPost(url, { pids })
        hideMessage()
        if (response.code === 0) {
            message.success('归档成功')
            return { pids, path }
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)

//批量删除
export const removeProjects = createAsyncThunk(
    'project/removeProjects',
    async ({ pids }, { dispatch }) => {

        let url = '/api/project/batchDelete'
        const hideMessage = message.loading('删除中...', 0)
        const response = await axPost(url, { pids })
        // The value we return becomes the `fulfilled` action payload
        hideMessage()
        if (response.code === 0) {
            message.success('删除成功')
            return pids
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)

export const deleteProject = createAsyncThunk(
    'project/deleteProject',
    async (pid, { dispatch }) => {
        const hideMessage = message.loading('删除中...', 0)
        const response = await axDelete('/api/project/' + pid)
        hideMessage()
        if (response.code === 0) {
            message.success('删除成功')
            return pid
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)


export const removeLocks = createAsyncThunk(
    'project/removeLocks',
    async (pid, { dispatch }) => {
        const hideMessage = message.loading('清除中...', 0)
        const response = await axPost('/api/project/removelocks/' + pid)
        hideMessage()
        if (response.code === 0) {
            message.success('清除成功')
            return pid
        } else {
            if (response.code === 401) {
                dispatch(showAccountModal(true))
            }
            throw '操作失败'
        }

    }
)


export const projectSlice = createSlice({
    name: 'project',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action) => {
        //     state.value += action.payload;
        // },
        showProjectModal: (state, action) => {
            state.showProjectModal = action.payload.visible
            state.selectedProject = action.payload.project || {}

        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.loading = false
                if (action.payload.page === 1) {
                    state.list = action.payload.rows
                } else {
                    state.list = state.list.concat(action.payload.rows)
                }
                state.page = action.payload.page
                state.hasMore = action.payload.rows.length === 20
            })
            .addCase(getProjects.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(updateProject.pending, (state) => {
                state.updatingProject = true
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.updatingProject = false
                const { isCreated, project } = action.payload
                if (isCreated) {
                    state.list.unshift(project)
                } else {
                    //let projects = state.list.concat()        
                    state.list.some((item, index) => {
                        if (item.pid === project.pid) {
                            state.list[index] = Object.assign({}, item, project)
                            return true
                        }
                        return false

                    })
                }
            })
            .addCase(updateProject.rejected, (state, action) => {
                state.updatingProject = false
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.list.some((project, index) => {
                    if (project.pid === action.payload) {
                        state.list.splice(index, 1)
                        return true
                    }
                    return false
                })
            })
            .addCase(moveProjects.fulfilled, (state, action) => {
                let pids = action.payload.pids
                let fid = action.payload.fid
                let activedFid = action.payload.activedFid

                state.list.some((project, index) => {
                    if (activedFid > 0 && activedFid !== fid) {
                        if (pids.indexOf(project.pid) !== -1) {
                            state.list.splice(index, 1)
                            return true
                        }
                    } else {
                        if (pids.indexOf(project.pid) !== -1) {
                            state.list[index] = Object.assign({}, project, { fid })
                            return true
                        }
                    }
                    return false
                })
            })
            .addCase(removeProjects.fulfilled, (state, action) => {
                state.list = state.list.filter(project => action.payload.indexOf(project.pid) === -1)
            })
            .addCase(fileProjects.fulfilled, (state, action) => {
                const { pids, path } = action.payload
                if (path === 'working' || path === '') {
                    state.list = state.list.filter(project => pids.indexOf(project.pid) === -1)
                }
            })
    },
});


export const { showProjectModal } = projectSlice.actions
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const updateProject = (project) => async (dispatch, getState) => {
//     // const currentValue = selectCount(getState());
//     // if (currentValue % 2 === 1) {
//     //     dispatch(incrementByAmount(amount));
//     // }
//     let url = '/api/project/'
//     if (project.pid) {
//         url += pid
//     }
//     const response = await axPost(url, project)
//     if (response.code === 0) {
//         message.success(project.pid ? '更新成功' : '创建成功')
//         return response.data
//     } else {
//         throw '操作失败'
//     }

// };

export default projectSlice.reducer;
